.templateList .customBtn:nth-child(2) {
  background-color: #5C6BE8;
  border: none;
}
.templateList .customBtn:nth-child(2):hover {
  background-color: #6875ee;
}
.templateList .code .codeimg-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.templateList .code .codeimg-wrap .codeimg {
  display: block;
  margin: 0 auto;
}
.templateList .code .codeimg-wrap .qrCodeName {
  margin-bottom: 20px;
}
.templateList .code .codeimg-wrap .el-button {
  margin: 0 auto;
  border: none;
  background-color: #5C6BE8;
}
.templateList .code .codeimg-wrap .el-button:hover {
  background-color: #6875ee;
}
