



















































































































































































































































































































































































































































































.templateList{
    .customBtn:nth-child(2){
        background-color: #5C6BE8;
        border: none;
        &:hover{
            background-color: #6875ee;
        }
    }
    .code{
        .codeimg-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .codeimg{
                display: block;
                margin: 0 auto;
            }
            .qrCodeName{
                margin-bottom: 20px;
            }
            .el-button{
                margin: 0 auto;
                border: none;
                background-color: #5C6BE8;
                &:hover{
                    background-color: #6875ee;
                }
            }
        }
    }
}
